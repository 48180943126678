@import "../../global.scss";

.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;
    overflow: visible;

    @include mobile {
      font-size: 30px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 80%;
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      width: 100%;
    }

    a {
      text-decoration: none;
    }

    .item {
      width: 300px;
      height: 250px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;
      cursor: pointer;
      text-align: center;

      @include mobile {
        width: 280px;
        height: 150px;
      }

      h3 {
        position: absolute;
        font-size: 20px;

        @include mobile {
          width: 150px;
          color: $mainColor;
          position: relative;
          font-size: 15px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}
