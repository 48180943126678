@import "../../global.scss";

.about {
  background-color: white;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 50px;
    overflow: visible;
    align-self: center;

    @include mobile {
      font-size: 30px;
    }
  }

  .aboutData {
    margin: 20px 10px;
    width: 95%;
    height: 700px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-y: hidden;

    .text {
      width: 95%;
      height: 500px;
      margin: 10px 3px;
      padding: 30px;

      flex-wrap: wrap;

      font-size: 20px;
      line-height: 1.6;

      b {
        font-style: italic;
        font-weight: 500;
      }

      .resumeLink {
        text-decoration: none;
        text-shadow: 1px;
        color: darkblue;
      }

      .socialIcons {
        margin: auto;
        width: 400px;
        height: 150px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          margin: 40px 35px;
          width: 40px;
          height: 40px;

          @include mobile {
            margin: 10px 15px;
          }
        }

        @include mobile {
          height: 80px;
          margin: 5px 15px;
        }
      }

      @include mobile {
        //text
        margin: 0px auto;
        height: auto;
        padding: 10px;
        font-size: 2.2vh;
        line-height: 1.45;
      }
    }

    @include mobile {
      //aboutData
      margin: 10px 10px;
      width: 95%;
      height: 90%;
      overflow: visible;
    }

    .image {
      margin-right: 20px;
      height: 450px;
      width: 400px;
      border-radius: 45%;
      overflow: hidden;

      @include mobile {
        display: none;
      }

      figure {
        position: relative;
        overflow: hidden;
      }

      figure::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        display: block;
        content: "";
        width: 0;
        height: 0;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0;
      }

      figure:hover::before {
        -webkit-animation: circle 0.75s;
        animation: circle 0.75s;
      }

      @-webkit-keyframes circle {
        0% {
          opacity: 1;
        }

        40% {
          opacity: 1;
        }

        100% {
          width: 200%;
          height: 200%;
          opacity: 0;
        }
      }

      @keyframes circle {
        0% {
          opacity: 1;
        }

        40% {
          opacity: 1;
        }

        100% {
          width: 200%;
          height: 200%;
          opacity: 0;
        }
      }

      .vkImage {
        height: 450px;
        width: 350px;
      }
    }
  }
}